import {FlatTreeControl} from '@angular/cdk/tree';
import {CommonModule} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';

import {AuthService} from '@indosuara/auth';
import {RemitNavigationService, RemitRoute} from '@indosuara/remit-api';
import {SubSink} from 'subsink';


interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  icon?: string;
  emoji?: string;
  url: string | undefined
}

@Component({
  selector: 'indosuara-navigation-menu',
  styleUrls: ['./navigation-menu.component.css'],
  templateUrl: './navigation-menu.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
  ],
})
export class NavigationMenuComponent implements OnDestroy, OnInit {
  constructor(
    private authSvc: AuthService,
    private navService: RemitNavigationService,
  ) {
    this.user$ = this.authSvc.authState();
  }

  user$;

  subs = new SubSink();
  treeControl =new FlatTreeControl<FlatNode>(
      (node) => node.level,
      (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
      this._transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.childrens,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  private _transformer(node: RemitRoute, level: number): FlatNode {
    return {
      expandable: !!node.childrens && node.childrens.length > 0,
      name: node.name,
      level: level,
      icon: node.icon,
      url: `remit/${node.path}`,
    };
  }


  ngOnInit(): void {
    this.subs.sink = this.navService.getRoutes().subscribe({
      next: (navs) => {
        this.dataSource.data = navs;
      },
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;
}

