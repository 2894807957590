import {Route} from '@angular/router';
import {canActivateLoggedInChild} from '@indosuara/auth';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

export const appRoutes: Route[] = [
  {
    path: '', redirectTo: '/remit', pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then(
          (m) => m.LoginComponent,
      ),
  },
  {
    path: 'login/:redirectUrl',
    loadComponent: () =>
      import('./login/login.component').then(
          (m) => m.LoginComponent,
      ),
  },
  {
    path: 'remit',
    loadChildren: () =>
      import('@indosuara/remit').then(
          (m) => m.RemitModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('@indosuara/auth-ui').then(
          (m) => m.LogoutComponent,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('@indosuara/auth-ui').then((m) => m.ChangePasswordComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: '**', component: PageNotFoundComponent,
    canActivate: [canActivateLoggedInChild],
  },
];
