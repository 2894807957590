
<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="parent-tree">
    <button mat-button matTreeNodeToggle class="parent-node" routerLinkActive="active">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
      <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
      {{ node.name }}
    </button>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node">
    <button
      mat-button
      [routerLink]="node.url"
      routerLinkActive="active"
      class="child-node"
    >
      <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
      {{ node.name }}
    </button>
  </mat-tree-node>
</mat-tree>
<mat-divider></mat-divider>
<ng-container *ngIf="user$ | async as user">
<div style="opacity: 0.6; font-size: small; padding: 0.5rem;">
  Logged in as {{user.name || '-'}} with email {{user.email}}.
</div>
</ng-container>
