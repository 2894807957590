<div  class="app-container">
  <mat-toolbar
  color="primary"
  *ngIf="(auth$| async) as auth"
  >
  <button
    *ngIf="auth"
    mat-icon-button
    (click)="sidenav.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div style="cursor: pointer;" routerLink="/">Pay88</div>
  <span class="flex-spacer"></span>
  <div
    *ngIf="isDevelopment()"
    matTooltip="This panel is connected to development server."
  >
    DEV
  </div>
  <button
    *ngIf="auth"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    matTooltip="Click to see more selections"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      *ngIf="auth"
      mat-menu-item
      routerLink="/logout"
      matTooltip="Sign out from this account"
      aria-label="Logout"
    >
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </mat-menu>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav
      #sidenav
      [mode]="isSmallScreen ? 'over' : 'side'"
      [fixedInViewport]="isSmallScreen"
      fixedTopGap="56"
      [(opened)]="opened"
    >
      <indosuara-navigation-menu></indosuara-navigation-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet class="main-router-outlet"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  
</div>
