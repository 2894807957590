import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {browserLocalPersistence, connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {setPersistence} from '@firebase/auth';
import {AuthHttpInterceptor, AuthService, FireAuthService} from '@indosuara/auth';
import {DEFAULT_ORDER_SHOP_CODE_FILTER, QINVENTORY_BASE_URL, REMIT_AUTO_ARC_BASE_URL, REMIT_BASE_URL} from '@indosuara/remit-api';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {NavigationMenuComponent} from './navigation-menu/navigation-menu.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {initialNavigation: 'enabledBlocking'}),
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatAutocompleteModule,
    NavigationMenuComponent],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      auth.tenantId = environment.staffTenantId ?? 'employee-bml6n';
      setPersistence(auth, browserLocalPersistence);
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    {
      provide: AuthService,
      useClass: FireAuthService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: REMIT_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/remitz/api/v0',
    },
    {
      provide: QINVENTORY_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/qinventory/api/v0/staff',
    },
    {
      provide: REMIT_AUTO_ARC_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/arc-automation',
    },
    {
      provide: DEFAULT_ORDER_SHOP_CODE_FILTER,
      useValue: 'P88',
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]})
export class AppModule {}
