import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@indosuara/auth';
import {combineLatest, tap} from 'rxjs';
import {SubSink} from 'subsink';
import {environment} from '../environments/environment';

@Component({
  selector: 'indosuara-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  opened = false;

  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.auth$ = this.authService.authState();
  }

  auth$;

  isDevelopment(): boolean {
    return !environment.production;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get isSmallScreen():boolean {
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  ngOnInit() {
    this.subs.sink = combineLatest([
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
      ]),
      this.authService.authState(),
    ])
        .pipe(
            tap(([mediaValue, authStatus]) => {
              if (!authStatus?.staff) {
                this.opened = false;
              } else {
                if (mediaValue.matches) {
                  this.opened = false;
                } else {
                  this.opened = true;
                }
              }
            }),
        )
        .subscribe();
  }
}
